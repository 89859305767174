:root{
  --blue-header: #4aa3a3;
  --blue-bg:#87b5b5;
  --blue-bg-blocks: #c0d8d8;
  --blue-btn: #56e2b1;
}

*, *::after, *::before {
       box-sizing: border-box;
}

html{
  font-size: 10;
  padding: 0;
  margin: 0;
}

html, body, #root{
  width: 100%;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-base{
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-bg);
}

#header{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue-header);
}

.title{
  color: black;
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
}

.resize-btn:hover{
  color: var(--blue-btn);
  cursor: pointer;
}

#editor-div, #preview-div{
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.5);
}

#editor-div{
  width: 600px;
  max-width: 90%;
}

#preview-div{
  width: 800px;
  max-width: 90%;
}

#editor, #preview{
  width: 100%;
  height: auto;
  background-color: var(--blue-bg-blocks);
}

#editor {
  width: 100%;
  height: 200px;
  font-size: 0.9em;
  min-height: 40%;
  display: block;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

}

button{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.hidden{
  display: none;
  visibility: hidden;
}
.maximized{
  height: auto;
  min-height: 100vh;
  background-color: green !important;
}

.maximized > #editor{
  height: auto;
  min-height: 100vh;
}